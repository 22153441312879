<template>
	<div id="result">
		<div class="resultBox">
			<div class="banner">
				<div class="logo">
					<img src="../../assets/imgs/logo.png">
				</div>
				<img class="back" src="../../assets/imgs/loginBack.png">
			</div>
			<div class="box">
				<div class="resultCont" v-if="token.type == 'Doctor'">
					<p>信息已提交</p>
					<span v-if="docInfo.status != 'SUCCESS'">审核结果将以短信形式推送至您的手机号码</span>
				</div>
				<div class="resultCont" v-else>
					<p>信息已提交</p>
					<span>账号信息将以短信形式推送至用户的手机号码</span>
				</div>
				<div class="options">
					<el-button v-if="token.type == 'Doctor'" class="next" @click="onSubmit()">进入首页</el-button>
					<el-button v-else class="next" @click="onSubmit()">确认</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getDoctorInfo
	} from '@/request/api.js';
	export default {
		name: 'result',
		data() {
			return {
				token: '',
				docInfo: ''
			}
		},
		created() {
			this.distinguish();
		},
		mounted() {},
		methods: {
			// 分辨登录对象
			distinguish() {
				let token = localStorage.getItem('token');
				let regToken = localStorage.getItem('regToken');
				if (regToken) {
					regToken = JSON.parse(regToken);
				}
				if (token) {
					token = JSON.parse(token);
					if (token.type == 'Doctor') {
						token = regToken;
						if (regToken) {
							localStorage.setItem('token', JSON.stringify(regToken));
						}
						this.doctorInfo();
					}
					if (token.type == 'ASSISTANT') {
						localStorage.removeItem('regToken');
					}
				} else {
					token = regToken;
					localStorage.setItem('token', JSON.stringify(regToken));
					this.doctorInfo();
				}
				this.token = token;
				localStorage.removeItem('regToken');
			},

			doctorInfo() {
				getDoctorInfo().then(res => {
					if (res.code == 200) {
						this.docInfo = res.data;
						console.log(res)
					}
				})
			},
			onSubmit() {

				this.token = JSON.parse(localStorage.getItem('token'));

				if (this.token.type == 'Doctor') {
					this.$router.push({
						name: 'meetlist'
					})
				} else if (this.token.type == 'ASSISTANT') {
					this.$router.push({
						name: 'option'
					})
				} else {
					this.$router.push({
						name: 'login'
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	#result {
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(244, 249, 253, 1);

		.resultBox {
			width: 90%;
			max-width: 1200px;
			height: 90%;
			display: flex;
			overflow: hidden;
			border-radius: 20px;
			flex-direction: row;
			box-shadow: 0 0 20px rgba(0, 0, 0, .1);

			.banner,
			.box {
				width: 600px;
				background-color: #fff;
			}

			.banner {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				.logo {
					flex: 1;
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					padding: 40px 0 0 40px;

					img {
						height: 80px;
					}
				}

				.back {
					width: 400px;
					margin-bottom: 100px;
				}
			}

			.box {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: 50px;
				overflow: hidden;
				overflow-y: auto;
				justify-content: center;

				.resultCont {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin-bottom: 50px;

					img {
						width: 180px;
						height: 180px;
						margin-bottom: 30px;
					}

					p {
						color: #112950;
						font-size: 28px;
						margin-bottom: 20px;
					}

					span {
						color: #a6a6a6;
						font-size: 15px;
					}
				}

				.options {
					display: flex;
					justify-content: center;
					margin-bottom: 30px;

					::v-deep .el-button {
						color: #0F62F9;
						border: 1px solid #0F62F9;
						width: 300px;
						height: 50px;
						font-size: 18px;
					}
				}
			}
		}
	}
</style>